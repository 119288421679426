<template>
  <div>
    <div class="geo-search">
      <div class="geo-title">{{ $t("Geofence.RouteProperties") }}</div>
      <b-button variant="primary" class="btn-icon" @click="redirectBackToList">
        <feather-icon icon="ArrowLeftIcon" />
        <span class="d-none d-sm-inline ml-1">{{ $t("Geofence.Back") }}</span>
      </b-button>
    </div>

    <validation-observer tag="form" #default="{ invalid }">
      <div ref="">
        <div class="geo-outer" ref="dynamicHeightDiv">
          <div class="input-outer">
            <b-form-group :label="$t('Geofence.RouteName') + ' *'">
              <validation-provider
                #default="{ errors }"
                name="name"
                vid="name"
                :rules="{
                  required: true,
                  min: constants.MIN_GEOFENCE_NAME,
                  max: constants.MAX_GEOFENCE_NAME
                }"
              >
                <b-form-input
                  :disabled="!isEditable"
                  v-model="route.name"
                  :placeholder="$t('Geofence.EnterRouteName')"
                />
                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="textarea-outer">
            <b-form-group :label="$t('Geofence.Description')">
              <validation-provider
                #default="{ errors }"
                name="description"
                vid="description"
                :rules="{
                  max: 400
                }"
              >
                <b-form-textarea
                  v-model="route.description"
                  rows="3"
                  :disabled="!isEditable"
                />
                <small class="text-danger">{{
                  errors[0] ? handleError(errors[0]) : ""
                }}</small>
              </validation-provider></b-form-group
            >
          </div>
          <div class="input-outer">
            <b-form-group :label="$t('Geofence.Label')">
              <TagMultiSelect
                v-model="route.labels"
                :class="!isEditable ? 'disabled' : ''"
              />
            </b-form-group>
          </div>
          <div class="input-outer">
            <b-form-group>
              <b-form-checkbox
                v-model="route.allow_child"
                :disabled="!isEditable"
                class="form-control-merge cursor-pointer"
              >
                {{ $t("route.AllowChild") }}
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div class="textarea-outer" :class="!isEditable ? 'disabled' : ''">
            <div class="area-input">
              <span class="in-label">{{ $t("Geofence.CheckPoints") }}</span>
              <span class="point-outer">
                <div
                  class="check-point"
                  :title="$t('route.locationPoint')"
                  v-b-tooltip.hover.v-primary
                  :class="
                    selectedCheckPoint === 'locationPoint' ? 'active' : ''
                  "
                  @click="openSearch('locationPoint')"
                >
                  <LocationPoint class="LocationPoint" />
                </div>
                <div
                  v-b-tooltip.hover.v-primary
                  :title="$t('route.geofence')"
                  :class="selectedCheckPoint === 'geofence' ? 'active' : ''"
                  class="check-point"
                  @click="openSearch('geofence')"
                >
                  <PolyMap class="PolyMap" />
                </div>
                <div
                  v-b-tooltip.hover.v-primary
                  :title="$t('route.unit')"
                  :class="selectedCheckPoint === 'unit' ? 'active' : ''"
                  class="check-point"
                  @click="openSearch('unit')"
                >
                  <CheckPoint class="CheckPoint" />
                </div>
              </span>
            </div>
          </div>
          <OptimizeCard
            :optimizeLoad="optimizeLoad"
            :isEditable="isEditable"
            v-if="
              optimizeLoad || (route.checkPoints && route.checkPoints.length)
            "
            v-model="route.checkPoints"
            :route="{ ...route, id: route.id || routeTempId }"
            @removedFromList="removedFromList"
            :geofenceControl="geofenceControl"
            @optimizeRoute="optimizeRoute"
          />
        </div>
        <div class="pagination-outer-1">
          <b-button
            variant="outline-primary"
            @click="cancel"
            :disabled="!isEditable"
            class="sub-icon"
          >
            {{ $t("Geofence.Cancel") }}</b-button
          >
          <b-button
            variant="primary"
            :disabled="
              invalid || showLoading || !route.checkPoints.length || !isEditable
            "
            class="sub-icon"
            @click="saveRoutes"
          >
            {{ $t("Geofence.Save") }}</b-button
          >
        </div>
      </div></validation-observer
    >
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormCheckbox,
  BAvatar,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormInput,
  VBTooltip,
  BFormTextarea
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import CheckPoint from "@/assets/images/check-point.svg";
import LocationPoint from "@/assets/images/location-point.svg";
import PolyMap from "@/assets/images/poly-map.svg";
import OptimizeCard from "./OptimizeCard.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UtilityService from "@/libs/api/utility-service";
import GeoService from "@/libs/api/geo-service";
export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BAvatar,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BFormTextarea,
    VueSlider,
    CheckPoint,
    LocationPoint,
    PolyMap,
    OptimizeCard,
    ValidationProvider,
    ValidationObserver,
    TagMultiSelect,
    ToastificationContent
  },
  data() {
    return {
      maxHeight: 0,
      showLoading: false,
      searchKey: "unit",
      optimizeLoad: false,
      routeTempId: Math.floor(Math.random() * 10000) + 1,
      tempRoute: {},
      selectedCheckPoint: "",
      isEditable: true,
      route: {
        name: "",
        labels: [],
        description: "",
        allow_child: true,
        isSearch: false,
        checkPoints: []
      }
    };
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  mounted() {
    this.setMaxHeight();
    if (this.$route.name === "route-view") {
      this.isEditable = false;
    }
    this.tempRoute = JSON.parse(JSON.stringify(this.route));
    window.addEventListener("resize", this.setMaxHeight);
    this.tempGeofence = JSON.parse(JSON.stringify(this.route));
    if (
      ["route-view", "route-edit"].includes(this.$route.name) &&
      this.$route.params.id
    ) {
      this.getRouteDetails(this.$route.params.id);
    }
    if (["route-create"].includes(this.$route.name) && this.$route.query.id) {
      this.getRouteDetails(this.$route.query.id);
    }
    setTimeout(() => {
      this.geofenceControl.events.on("clickSelectedGeoFence", (data) => {
        if (data.isSelected) {
          const item = data;
          const checkPoint = this.route.checkPoints.find(
            (e) => e.data.id === item.data.id
          );
          if (checkPoint) {
            return;
          }
          const index = { index: this.route.checkPoints.length + 1 };
          if (
            item.data &&
            item.selectedType === "geofence" &&
            item.data.boundary &&
            item.data.boundary.type
          ) {
            this.addGeoFenceLayer(item.data, index);
            this.route.checkPoints.push({
              type: item.selectedType,
              data: item.data,
              radius: 100
            });
          } else if (data.data && data.selectedType === "unit") {
            const item = data;
            this.addUnitMarkerLayer(item.data, index);
            this.route.checkPoints.push({
              type: item.selectedType,
              data: item.data,
              radius: 100
            });
          }
          this.$store.commit(
            "route/SET_ROUTE_CHECKPOINT",
            this.route.checkPoints
          );
        } else {
          this.removedFromList(data);
          this.geofenceControl.removeLayerById(data.data.id);
        }
      });
      this.geofenceControl.events.on("routeOptimized", (data) => {
        const sortedA = data.orderedWaypoints.map(({ layerId }) =>
          this.route.checkPoints.find(({ data }) => data.id === layerId)
        );
        this.route.checkPoints = sortedA;
      });
      this.geofenceControl.events.on("drawGeoFence", async (data) => {
        const id = Math.floor(Math.random() * 10000) + 1;
        const item = {
          type: "point",
          radius: 100,
          data: {
            id: id,
            icon: `${this.getCurrentPath()}/app/images/marker-icon.png`,
            name: await this.getLocation(
              data.latlngs[0].lat,
              data.latlngs[0].lng
            ),
            boundary: {
              type: "point",
              coordinates: [data.latlngs[0].lat, data.latlngs[0].lng]
            }
          }
        };
        this.route.checkPoints.push({
          ...item
        });

        this.geofenceControl.drawnItems.eachLayer((layer) => {
          if (data.layer === layer) {
            layer.options.id = id;
            layer.options.route_id = this.route.id || this.routeTempId;
            layer.options.index = this.route.checkPoints.length;
          }
        });

        data.layer.options.id = item.data.id;
        setTimeout(() => {
          if (this.layer && this.layer.options) {
            this.geofenceControl._data.forEach((layer) => {
              if (this.layer === layer) {
                layer.options.color = this.geoFence.color;
              }
              return layer;
            });
          }
          this.geofenceControl.removePolyline();
          this.geofenceControl.createSinglePolyline({
            id: this.route.id || this.routeTempId,
            color: "blue"
          });
        }, 1000);
      });
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setMaxHeight);
    this.geofenceControl.events.off("clickSelectedGeoFence");
    this.geofenceControl.events.off("drawGeoFence");
    this.geofenceControl.events.off("routeOptimized");
  },
  props: ["geofenceControl"],
  methods: {
    test() {},
    redirectBackToList() {
      this.$router.push({ name: "routes-list" });
    },

    setMaxHeight() {
      const windowHeight = window.innerHeight;
      const offset = 280; // Example offset value
      this.maxHeight = windowHeight - offset;
      this.$refs.dynamicHeightDiv.style.height = `${this.maxHeight}px`;
    },
    openSearch(key) {
      this.$emit("setSearchKey", key);
      this.selectedCheckPoint = key;
    },
    optimizeRoute() {
      this.geofenceControl.optimizeRoute({
        id: this.route.id || this.routeTempId,
        color: "blue"
      });
    },
    removedFromList(item) {
      const props = item && item.data;
      if (props && props.id) {
        this.route.checkPoints = this.route.checkPoints.filter(
          (e) => e.data.id != props.id
        );
        this.geofenceControl.removeLayerById(props.id);
        setTimeout(() => {
          this.geofenceControl.removePolyline();
          this.geofenceControl.createSinglePolyline({
            id: this.route.id || this.routeTempId,
            color: "blue"
          });
          this.$store.commit(
            "route/SET_ROUTE_CHECKPOINT",
            this.route.checkPoints
          );
        }, 1000);
      } else {
        this.route.checkPoints = [];
        this.geofenceControl.removeAllLayers();
        setTimeout(() => {
          this.geofenceControl.createSinglePolyline({
            id: this.route.id || this.routeTempId,
            color: "blue"
          });
          this.$store.commit(
            "route/SET_ROUTE_CHECKPOINT",
            this.route.checkPoints
          );
        }, 1000);
      }
      this.$emit("setSearchKey", "");
      this.selectedCheckPoint = "";
    },
    async saveRoutes() {
      try {
        this.showLoading = true;
        let response;

        //  await this.validationForm();

        let message = this.$t("route.CreatedSuccessfully");
        if (this.$route.query.id && this.route.id) {
          delete this.route.id;
        }
        const checkPoints = this.route.checkPoints.map((e) => {
          const checkPoint = {};
          if (e.type === "unit") {
            checkPoint.type = "unit";
            checkPoint.id = e.data.id;
            checkPoint.radius = Number(e.radius) || 100;
          } else if (e.type === "geofence") {
            checkPoint.type = "geofence";
            checkPoint.id = e.data.id;
            checkPoint.radius = Number(e.radius) || 100;
          } else if (e.type === "point") {
            checkPoint.type = "point";
            checkPoint.coordinates = e.data.boundary.coordinates;
            checkPoint.radius = Number(e.radius) || 100;
          }
          return checkPoint;
        });
        if (!this.$route.params.id) {
          response = await new UtilityService().createRoute({
            ...this.route,
            checkPoints
          });
        } else {
          response = await new UtilityService().updateRoute({
            ...this.route,
            checkPoints,
            route_id: this.$route.params.id,
            account_id: this.$route.query.account_id
          });
          message = this.$t("route.updatedSuccessfully");
        }

        this.showLoading = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$router.push({ name: "routes-list" });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getRouteDetails(id) {
      try {
        this.showLoading = true;
        let response = await new UtilityService().getRouteDetails({
          route_id: id
        });
        this.showLoading = false;
        if (response && response.data) {
          response.data.checkPoints = response.data.checkPoints.filter(
            (e) => e && e.type
          );
          const data = response.data;

          this.route = {
            labels: data.labels,
            id: data.id,
            name: this.$route.query.id ? data.name + " copy" : data.name,
            description: data.description,
            allow_child: data.allow_child || false,
            checkPoints: []
          };
          if (
            data.is_editable ||
            (["route-create"].includes(this.$route.name) &&
              this.$route.query.id)
          ) {
            this.isEditable = true;
          } else {
            this.isEditable = false;
          }

          this.$nextTick(async () => {
            this.optimizeLoad = true;
            const updatedCheckPoints = await Promise.all(
              data.checkPoints.map(async (checkPoint, index) => {
                data.index = index + 1;
                if (checkPoint && checkPoint.type) {
                  if (checkPoint.type === "unit") {
                    this.addUnitMarkerLayer(checkPoint.data, data);
                  } else if (checkPoint.type === "geofence") {
                    this.addGeoFenceLayer(checkPoint.data, data);
                  } else if (checkPoint.type === "point") {
                    checkPoint.data.id = Math.floor(Math.random() * 10000) + 1;
                    this.addUnitMarkerLayer(checkPoint.data, data);
                    checkPoint.data.icon = `${this.getCurrentPath()}/app/images/marker-icon.png`;
                    const boundary = checkPoint.data.boundary;
                    if (
                      boundary &&
                      boundary.coordinates &&
                      boundary.coordinates.length
                    ) {
                      checkPoint.data.name = await this.getLocation(
                        boundary.coordinates[0],
                        boundary.coordinates[1]
                      );
                    }
                  }
                }
                return checkPoint; // Return the modified checkpoint
              })
            );

            // Now set the updated checkpoints to this.route.checkPoints
            this.route.checkPoints = updatedCheckPoints;
            if (updatedCheckPoints && updatedCheckPoints.length) {
              this.optimizeLoad = false;
            } else {
              this.optimizeLoad = false;
            }
          });

          this.tempRoute = JSON.parse(JSON.stringify(this.route));
          setTimeout(() => {
            this.geofenceControl.removePolyline();
            this.geofenceControl.createSinglePolyline({
              id: this.route.id || this.routeTempId,
              color: "blue"
            });
          }, 1000);
          this.$store.commit(
            "route/SET_ROUTE_CHECKPOINT",
            this.route.checkPoints
          );
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },

    addGeoFenceLayer(data, item) {
      this.geofenceControl.addLayer({
        type: data.boundary.type
          ? data.boundary.type.toLowerCase()
          : data.boundary.type,
        coordinates: data.boundary.coordinates,
        id: data.id,
        index: item.index,
        color: data.color,
        radius: data.boundary.radius,
        route_id: this.route.id || this.routeTempId
      });
      setTimeout(() => {
        this.geofenceControl.removePolyline();
        this.geofenceControl.createSinglePolyline({
          id: this.route.id || this.routeTempId,
          color: "blue"
        });
      }, 1000);
    },
    addUnitMarkerLayer(data, item) {
      this.geofenceControl.removePolyline();
      this.geofenceControl.addLayer({
        type: "marker",
        coordinates: data.boundary.coordinates,
        id: data.id,
        icon:
          data.icon || `${this.getCurrentPath()}/app/images/marker-icon.png`,
        angle: data.angle || 0,
        index: item.index,
        route_id: this.route.id || this.routeTempId
      });

      this.geofenceControl.createSinglePolyline({
        id: this.route.id || this.routeTempId,
        color: "blue"
      });
    },
    cancel() {
      if (this.geofenceControl) {
        this.geofenceControl.removeAllLayers();
      }
      this.selectedCheckPoint = "";
      this.$emit("setSearchKey", "");
      this.route = JSON.parse(JSON.stringify(this.tempRoute));
      this.$nextTick(() => {
        const data = JSON.parse(JSON.stringify(this.tempRoute));
        data.checkPoints.forEach(async (checkPoint, index) => {
          data.index = index;
          if (checkPoint && checkPoint.type) {
            if (checkPoint.type === "unit") {
              this.addUnitMarkerLayer(checkPoint.data, data);
            } else if (checkPoint.type === "geofence") {
              this.addGeoFenceLayer(checkPoint.data, data);
            } else if (checkPoint.type === "point") {
              this.addUnitMarkerLayer(checkPoint.data, data);
              checkPoint.data.icon = `${this.getCurrentPath()}/app/images/marker-icon.png`;
              const boundary = checkPoint.data.boundary;
              if (
                boundary &&
                boundary.coordinates &&
                boundary.coordinates.length
              ) {
                checkPoint.data.name = await this.getLocation(
                  boundary.coordinates[0],
                  boundary.coordinates[1]
                );
              }
            }
          }
        });
        this.route.checkPoints = data.checkPoints;
      });

      this.tempRoute = JSON.parse(JSON.stringify(this.route));
      setTimeout(() => {
        this.geofenceControl.removePolyline();
        this.geofenceControl.createSinglePolyline({
          id: this.route.id || this.routeTempId,
          color: "blue"
        });
      }, 1000);
      this.$store.commit("route/SET_ROUTE_CHECKPOINT", this.route.checkPoints);
    },
    async getLocation(lat, lng) {
      try {
        let response = await new GeoService().findAddressFromCordinates({
          lat: lat ? lat.toString() : lat,
          lng: lng ? lng.toString() : lng
        });
        if (response && response.data) {
          this.addressLoading = false;
          return response.data.display_name
            ? response.data.display_name
            : [lat.toString(), lng.toString()];
        } else if (response && response.error && response.error.message) {
          return response.error.message;
        }
      } catch (err) {
        return err.message;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.geo-outer {
  padding: 15px;
  overflow-y: auto;
}
.geo-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px !important;
  .btn-icon {
    width: 90px;
    padding: 0 10px;
  }
}
.geo-title {
  font-size: 15px;
  font-weight: 500;
}
.textarea-outer {
  margin-bottom: 1rem;
}
.area-input {
  border-radius: 4px;
  height: 42px;
  line-height: 42px;
  padding: 0 10px;
  margin-bottom: 15px;
  border: 1px solid #d8d6de;
  display: flex;
  justify-content: space-between;
}

.point-outer {
  display: flex;
}
.in-label {
  font-size: 12px;
  font-weight: 500;
}
.check-point {
  height: 33px;
  width: 33px;
  border: 1px solid #d8d6de;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 4px;
  cursor: pointer;
  border-radius: 2px;
}
.check-point.active {
  background-color: var(--light-primary);
  .CheckPoint {
    path {
      stroke: var(--primary);
    }
  }
  .LocationPoint {
    path {
      fill: var(--primary);
    }
  }
  .PolyMap {
    path {
      stroke: var(--primary);
    }
    circle {
      stroke: var(--primary);
      fill: var(--primary);
    }
  }
}
.pagination-outer-1 {
  display: flex;
  justify-content: flex-end;
  padding: 10px 8px 0 8px;
  .sub-icon {
    margin-left: 8px;
    margin-right: 8px;
  }
}
</style>
